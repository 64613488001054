module services {
    export module master {
        export class tariffPriorityService implements interfaces.master.ITariffPriorityService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            //Gets list of all TariffPriority for a dropdown/autocomplete list
            getDropdownList(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "TariffPriority/GetDropdownList", {
                    ownerEntityId: '@ownerEntityId'
                });
            }
        }
    }
    angular.module("app").service("tariffPriorityService", services.master.tariffPriorityService);
}